<i18n>
{
  "en": {
    "delete-title": "Delete company",
    "data-section-1": "Company Details",
    "data-section-2": "Location",
    "data-section-3": "Bank Details",
    "data-section-4": "Custom Fields",
    "data-section-input-1": "Corporate name",
    "data-section-input-2": "Commercial name",
    "data-section-input-3": "E-mail",
    "data-section-input-4": "Website",
    "data-section-input-5": "Main phone",
    "data-section-input-6": "Secondary phone",
    "data-section-input-7": "CNPJ",
    "data-section-input-8": "CEP",
    "data-section-input-9": "Address",
    "data-section-input-10": "Number",
    "data-section-input-11": "District",
    "data-section-input-12": "Complements",
    "data-section-input-13": "City",
    "data-section-input-14": "State",
    "data-section-input-15": "Country",
    "data-section-input-16": "Bank name",
    "data-section-input-17": "Code",
    "data-section-input-18": "Agency",
    "data-section-input-19": "Account",
    "data-section-input-20": "Account type",
    "data-section-input-21": "Account owner",
    "data-section-input-22": "Owner document",
    "attachments-section": "Attachments",
    "activities-section": "Activities",
    "created-at": "Created at",
    "updated-at": "Updated at",
    "save": "Save updates",
    "save-mobile": "Save",
    "bank-account-type-1": "Checking",
    "bank-account-type-2": "Savings",
    "bank-account-owner-1": "Yes",
    "bank-account-owner-2": "No"
  },
  "pt-BR": {
    "delete-title": "Deletar empresa",
    "data-section-1": "Dados da Empresa",
    "data-section-2": "Localização",
    "data-section-3": "Dados Bancários",
    "data-section-4": "Campos Customizados",
    "data-section-input-1": "Razão social",
    "data-section-input-2": "Nome fantasia",
    "data-section-input-3": "E-mail",
    "data-section-input-4": "Website",
    "data-section-input-5": "Telefone principal",
    "data-section-input-6": "Telefone secundário",
    "data-section-input-7": "CNPJ",
    "data-section-input-8": "CEP",
    "data-section-input-9": "Rua",
    "data-section-input-10": "Número",
    "data-section-input-11": "Bairro",
    "data-section-input-12": "Complemento",
    "data-section-input-13": "Cidade",
    "data-section-input-14": "Estado",
    "data-section-input-15": "País",
    "data-section-input-16": "Nome do banco",
    "data-section-input-17": "Código",
    "data-section-input-18": "Agência",
    "data-section-input-19": "Conta",
    "data-section-input-20": "Tipo de Conta",
    "data-section-input-21": "Favorecido da conta",
    "data-section-input-22": "Documento do favorecido",
    "attachments-section": "Anexos",
    "activities-section": "Atividades",
    "created-at": "Criado em",
    "updated-at": "Última atualização",
    "save": "Salvar alterações",
    "save-mobile": "Salvar",
    "bank-account-type-1": "Corrente",
    "bank-account-type-2": "Poupança",
    "bank-account-owner-1": "Sim",
    "bank-account-owner-2": "Não"
  }
}
</i18n>

<template>
  <modal-entity
    class="company"
    @close="closeCompany"
    @drop="drop"
    :menuItems="menuItems"
    @currentMenuItem="currentMenuItem = $event"
  >
    <template #actions>
      <confirm-modal
        :open="confirmDeleteOpened"
        @close="confirmDeleteOpened = false"
        @confirm="deleteCompany(company.id)"
      />
      <we-icon-button
        :icon="['far', 'trash-alt']"
        :name="$t('delete-title')"
        direction="bottom"
        class="big-mobile"
        @click="confirmDeleteOpened = true"
        cy="delete-company"
      />
      <we-icon-button icon="times" @click="closeCompany" class="big-mobile" />
    </template>
    <template #data>
      <div class="data-grid">
        <we-picture-change
          entity="company"
          type="square"
          size="79"
          :name="company.company"
          :picture="company.picture"
          :loading="imgLoading"
          @onFileChange="onFileChange"
          @delete="deleteCompanyImage(company.id)"
        />
        <div class="data-info">
          <we-text-tooltip class="name" :text="company.company" lines="1" />
          <div class="data-list">
            <span class="email">{{ company.document }}</span>
          </div>
        </div>
      </div>
    </template>
    <template #body>
      <v-form ref="form">
        <section
          id="profile"
          name="profile"
          :class="{ 'hide-mobile': currentMenuItem != 'profile' }"
        >
          <div class="title">
            <div class="icon">
              <font-awesome-icon icon="building" />
            </div>
            <span class="section-name">{{ $t("data-section-1") }}</span>
          </div>
          <div class="fields">
            <we-input
              class="fill-input"
              v-model="company.company"
              isRequired
              type="text"
              :label="$t('data-section-input-1')"
            />
            <we-input
              class="fill-input"
              v-model="company.name"
              type="text"
              :label="$t('data-section-input-2')"
            />
            <we-input
              class="fill-input"
              v-model="company.email"
              type="email"
              :label="$t('data-section-input-3')"
            />
            <we-input
              class="fill-input"
              v-model="company.website"
              type="text"
              :label="$t('data-section-input-4')"
            />
            <we-input
              v-model="company.phone1"
              type="text"
              :label="$t('data-section-input-5')"
              :mask="[$root.$t('phone-mask'), $root.$t('phone-second-mask')]"
            />
            <we-input
              v-model="company.phone2"
              type="text"
              :label="$t('data-section-input-6')"
              :mask="[$root.$t('phone-mask'), $root.$t('phone-second-mask')]"
            />
            <we-input
              v-model="company.document"
              type="text"
              :label="$t('data-section-input-7')"
              :mask="'##.###.###/####-##'"
            />
          </div>
        </section>
        <section
          id="address"
          name="address"
          :class="{ 'hide-mobile': currentMenuItem != 'address' }"
        >
          <div class="title">
            <div class="icon">
              <font-awesome-icon icon="globe-americas" />
            </div>
            <span class="section-name">{{ $t("data-section-2") }}</span>
          </div>
          <div class="fields">
            <we-input
              v-model="company.address_zipcode"
              type="text"
              :label="$t('data-section-input-8')"
              :mask="$root.$t('zipcode-mask')"
              @blur="getCep($event)"
            />
            <we-input
              v-model="company.address_street"
              type="text"
              :label="$t('data-section-input-9')"
              :loading="cepLoading"
            />
            <we-input
              v-model="company.address_number"
              :mask="'######'"
              type="text"
              :label="$t('data-section-input-10')"
            />
            <we-input
              v-model="company.address_block"
              type="text"
              :label="$t('data-section-input-11')"
              :loading="cepLoading"
            />
            <we-input
              v-model="company.address_note"
              type="text"
              :label="$t('data-section-input-12')"
            />
            <we-input
              v-model="company.address_city"
              type="text"
              :label="$t('data-section-input-13')"
              :loading="cepLoading"
            />
            <we-input
              v-model="company.address_state"
              type="text"
              :label="$t('data-section-input-14')"
              :loading="cepLoading"
            />
            <we-input
              v-model="company.address_country"
              type="text"
              :label="$t('data-section-input-15')"
              :loading="cepLoading"
            />
          </div>
        </section>
        <section
          id="bank"
          name="bank"
          :class="{ 'hide-mobile': currentMenuItem != 'bank' }"
        >
          <div class="title">
            <div class="icon">
              <font-awesome-icon
                :icon="{ prefix: 'far', iconName: 'credit-card' }"
              />
            </div>
            <span class="section-name">{{ $t("data-section-3") }}</span>
          </div>
          <div class="fields">
            <we-input
              v-model="company.bank_name"
              type="text"
              :label="$t('data-section-input-16')"
            />
            <we-input
              v-model="company.bank_code"
              type="text"
              :mask="'######'"
              :label="$t('data-section-input-17')"
            />
            <we-input
              v-model="company.bank_branch"
              type="text"
              :mask="[
                $root.$t('bank-agency-mask-1'),
                $root.$t('bank-agency-mask-2')
              ]"
              :label="$t('data-section-input-18')"
            />
            <we-input
              v-model="company.bank_account"
              type="text"
              mask="###################"
              :label="$t('data-section-input-19')"
            />
            <we-input
              :label="$t('data-section-input-20')"
              type="select"
              v-model="company.bank_account_type"
              :items="[$t('bank-account-type-1'), $t('bank-account-type-2')]"
            />
            <we-input
              :label="$t('data-section-input-21')"
              type="select"
              v-model="company.bank_account_owner"
              :items="[$t('bank-account-owner-1'), $t('bank-account-owner-2')]"
            />
            <we-input
              v-model="company.bank_account_owner_document"
              type="text"
              :label="$t('data-section-input-22')"
              :mask="[$root.$t('cpf-mask'), $root.$t('cnpj-mask')]"
            />
          </div>
        </section>
        <section
          id="custom-fields"
          name="custom-fields"
          :class="{ 'hide-mobile': currentMenuItem != 'custom-fields' }"
        >
          <div class="title">
            <div class="icon">
              <font-awesome-icon icon="folder-plus" />
            </div>
            <span class="section-name">{{ $t("data-section-4") }}</span>
          </div>
          <div class="fields">
            <we-input
              v-for="(field, i) in customFieldsInputs"
              :key="i"
              :placeholder="field.placeholder"
              :type="field.type"
              :label="field.label"
              :items="field.values"
              :isRequired="field.is_required"
              :readonly="field.is_read_only"
              v-model="companyCustomFields[field.name]"
              :mask="field.mask"
              :class="field.layout"
            />
          </div>
        </section>
      </v-form>
      <section
        id="attachments"
        name="attachments"
        :class="{ 'hide-mobile': currentMenuItem != 'attachments' }"
      >
        <div class="title">
          <div class="icon">
            <font-awesome-icon icon="paperclip" />
          </div>
          <span class="section-name">{{ $t("attachments-section") }}</span>
        </div>
        <div class="fields fill">
          <drop-file
            ref="dropFileComponent"
            entity="company"
            :recordId="company.id"
          />
        </div>
      </section>
      <section
        id="activities"
        name="activities"
        :class="{ 'hide-mobile': currentMenuItem != 'activities' }"
      >
        <div class="title">
          <div class="icon">
            <font-awesome-icon icon="tasks" />
          </div>
          <span class="section-name">{{ $t("activities-section") }}</span>
        </div>
        <div class="fields fill">
          <activities
            ref="activitiesComponent"
            entity="company"
            :recordId="company.id"
            relatedContacts
          />
        </div>
      </section>
    </template>
    <template #bottom>
      <div class="history">
        <div class="icon">
          <font-awesome-icon icon="history" />
        </div>
        <div class="data">
          <div class="item">
            {{ $t("created-at") }}:
            <span>{{ company.created_at | dateTime }}</span>
          </div>
          <div class="item">
            {{ $t("updated-at") }}:
            <span>{{ company.updated_at | dateTime }}</span>
          </div>
        </div>
      </div>
      <we-button
        @click="updateCompany(company)"
        class="green mobile-icon"
        :text="$t('save')"
        :mobile-text="$t('save-mobile')"
        icon="check"
        :loading="btnLoading"
      />
    </template>
  </modal-entity>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      confirmDeleteOpened: false,
      loading: true,
      btnLoading: false,
      imgLoading: false,
      picture: {},
      cepLoading: false,
      reloadCompanies: false,
      currentMenuItem: "profile"
    };
  },
  computed: {
    menuItems() {
      return [
        {
          id: "profile",
          name: this.$t("data-section-1"),
          icon: "building"
        },
        {
          id: "address",
          name: this.$t("data-section-2"),
          icon: "globe-americas"
        },
        {
          id: "bank",
          name: this.$t("data-section-3"),
          icon: ["far", "credit-card"]
        },
        {
          id: "custom-fields",
          name: this.$t("data-section-4"),
          icon: "folder-plus"
        },
        {
          id: "attachments",
          name: this.$t("attachments-section"),
          icon: "paperclip"
        },
        {
          id: "activities",
          name: this.$t("activities-section"),
          icon: "tasks"
        }
      ];
    },
    company() {
      return this.$store.getters.getCurrentCompany;
    },
    customFieldsInputs() {
      return this.$store.getters.getCompanyCustomFieldsInputs;
    },
    companyCustomFields() {
      return this.$store.getters.getCompanyCustomFields;
    },
    mapPosition() {
      return {
        lat: parseFloat(this.company.address_latitude),
        lng: parseFloat(this.company.address_longitude)
      };
    }
  },
  methods: {
    ...mapActions([
      "companyRequest",
      "updateCompanyRequest",
      "deleteCompanyRequest",
      "updateCompanyPictureRequest",
      "deleteCompanyPictureRequest",
      "customFieldsRequest",
      "cepRequest"
    ]),
    async updateCompany(company) {
      this.btnLoading = true;
      this.reloadCompanies = true;
      company.custom_fields = [this.companyCustomFields];
      await this.updateCompanyRequest(company);
      this.btnLoading = false;
    },
    async deleteCompany(id) {
      this.loading = true;
      this.reloadCompanies = true;
      await this.deleteCompanyRequest(id);
      this.loading = false;
      this.closeCompany();
    },
    async onFileChange(e) {
      const file = e.target.files[0];
      this.picture = file;
      this.imgLoading = true;
      this.reloadCompanies = true;
      await this.updateCompanyPictureRequest(this.picture);
      this.imgLoading = false;
    },
    async deleteCompanyImage(id) {
      this.imgLoading = true;
      this.reloadCompanies = true;
      await this.deleteCompanyPictureRequest(id);
      this.imgLoading = false;
    },
    async getCep(number) {
      if (number.length === 9) {
        this.cepLoading = true;
        await this.cepRequest(number.split("-").join(""));
        this.cepLoading = false;
      }
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    drop(event) {
      this.$refs.dropFileComponent.onChange(event.dataTransfer.files);
    },
    closeCompany() {
      this.$router.push({
        name: "companies",
        query: { reload: this.reloadCompanies }
      });
    }
  },
  async mounted() {
    await this.companyRequest(this.$route.params.companyId);

    document.title = this.company.company;
    await this.customFieldsRequest();
    await this.$refs.activitiesComponent.allNotesRequest();
    await this.$refs.dropFileComponent.allFilesRequest();
    this.$refs.form.validate();
    this.loading = false;
  }
};
</script>

<style lang="scss"></style>
