<i18n>
{
  "en": {
    "title": "Companies",
    "search": "Search for corporate name, phone or cnpj...",
    "create": "New company",
    "create-mobile": "New",
    "filter": "Filter",
    "export-results": "Export results",
    "edit-filtered": "Edit filtered data",
    "delete-filtered": "Delete filtered data",
    "as-csv": "as CSV",
    "reload": "Reload",
    "export": "Export",
    "export-all": "Export all",
    "import": "Import",
    "not-found": "Companies not found",
    "not-found-description": "Check your search or filter again or click on button below to create a new one",
    "column-1": "Corporate name",
    "column-2": "Phone",
    "column-3": "Document",
    "column-4": "Created at",
    "column-5": "Updated at",
    "email-send-error-title": "Wasn't possible to send email!",
    "email-send-error-description": "This company may not have a registered email.",
    "message-send-error-title": "Wasn't possible to open Whatsapp!",
    "message-send-error-description": "This contact may not have a registered phone number."
  },
  "pt-BR": {
    "title": "Empresas",
    "search": "Pesquise pela razão social, telefone, cnpj...",
    "create": "Nova empresa",
    "create-mobile": "Nova",
    "filter": "Filtro",
    "export-results": "Exportar resultados",
    "edit-filtered": "Editar registros filtrados",
    "delete-filtered": "Deletar registros filtrados",
    "as-csv": "como CSV",
    "reload": "Recarregar",
    "export": "Exportar",
    "export-all": "Exportar tudo",
    "import": "Importar",
    "not-found": "Nenhumo card encontrada",
    "not-found-description": "Verifique sua pesquisa novamente ou clique no botão abaixo para criar",
    "column-1": "Razão social",
    "column-2": "Telefone",
    "column-3": "Documento",
    "column-4": "Data de cadastro",
    "column-5": "Última atualização",
    "email-send-error-title": "Não foi possível enviar email!",
    "email-send-error-description": "Essa empresa não possui email registrado.",
    "message-send-error-title": "Não foi possível abrir o Whatsapp!",
    "message-send-error-description": "Esse contato não possui número de telefone registrado."
  }
}
</i18n>

<template>
  <div id="companies">
    <nav-top :title="$t('title')" :returnPage="!isDesktop ? 'records' : null">
      <template #top-content>
        <we-input
          type="search"
          v-model="search"
          @input="searchCompanies"
          :inputLabel="$t('search')"
          cy="search-in-companies"
        ></we-input>
        <we-button
          class="green mobile-icon"
          cy="create-company-cta"
          :text="$t('create')"
          :mobile-text="$t('create-mobile')"
          icon="building"
          @click="$router.push({ name: 'createCompany' })"
        />
      </template>
    </nav-top>
    <board-list class="companies">
      <router-view />
      <template #filters>
        <div class="items">
          <we-pagination :pages="companiesPages" @change="selectPage($event)" />
          <we-button
            class="mobile-icon"
            :class="filterActive ? 'primary' : 'disabled'"
            :text="$t('filter')"
            icon="sliders-h"
            @click="$router.push({ name: 'filterCompany' })"
            cy="filter-in-companies"
          />
          <we-balloon v-if="filterActive">
            <template #action>
              <we-icon-button
                icon="download"
                name="Exportar Resultados"
                color="icon-primary"
              />
            </template>
            <template #balloon>
              <div class="balloon-item" @click="exportFilteredFile('csv')">
                <font-awesome-icon icon="file-csv" />
                <div class="text">
                  {{ $t("export-results") }} <b>{{ $t("as-csv") }}</b>
                </div>
              </div>
            </template>
          </we-balloon>
          <we-icon-button
            v-if="filterActive"
            :icon="['fas', 'edit']"
            :name="$t('edit-filtered')"
            @click="massUpdateOpened = true"
            cy="update-filtered-companies"
          />
          <mass-update
            :open="massUpdateOpened"
            @close="massUpdateOpened = false"
            @confirm="doRequests"
            :total-records="companiesPages.total"
          />
          <we-icon-button
            v-if="filterActive"
            :icon="['far', 'trash-alt']"
            :name="$t('delete-filtered')"
            color="icon-red"
            @click="confirmDeleteOpened = true"
            cy="delete-filtered-companies"
          />
          <confirm-modal
            :open="confirmDeleteOpened"
            :total-records="companiesPages.total"
            @confirm="deleteFilteredData()"
            @close="confirmDeleteOpened = false"
          />
        </div>
      </template>
      <template #actions>
        <we-icon-button
          icon="sync-alt"
          @click="doRequests(true)"
          :name="$t('reload')"
          cy="reload-companies"
        />
        <we-balloon>
          <template #action>
            <we-icon-button
              icon="download"
              :name="$t('export')"
              cy="export-companies-trigger"
            />
          </template>
          <template #balloon>
            <div class="balloon-item" @click="exportFile">
              <font-awesome-icon icon="file-csv" />
              <div class="text" cy="export-companies">
                {{ $t("export-all") }} <b>{{ $t("as-csv") }}</b>
              </div>
            </div>
          </template>
        </we-balloon>
        <we-icon-button
          icon="file-upload"
          @click="openImport"
          :name="$t('import')"
          cy="import-companies"
        />
      </template>
      <template #list>
        <we-loading-overflow :loading="loadingCompanies" />
        <div class="labels">
          <div
            class="label"
            :class="
              elem.orderBy === companiesSort.orderBy
                ? companiesSort.direction
                : ''
            "
            v-for="elem in sortElements"
            :key="elem.orderBy"
            @click="orderBy(elem)"
          >
            <we-text-tooltip class="name" :text="elem.name" lines="1" />
            <div class="icon">
              <font-awesome-icon icon="sort-up" />
              <font-awesome-icon icon="sort-down" />
            </div>
          </div>
        </div>
        <div class="items">
          <we-not-found v-if="companies.length === 0">
            <template #title> {{ $t("not-found") }} </template>
            <template #description>
              {{ $t("not-found-description") }}
            </template>
            <template #actions>
              <we-button
                class="green"
                :text="$t('create')"
                icon="building"
                @click="$router.push({ name: 'createCompany' })"
              />
            </template>
          </we-not-found>
          <div
            class="card-list"
            v-for="(company, i) in companies"
            :key="i"
            @click="openCompany(company.id)"
          >
            <div class="column profile">
              <we-rounded-picture
                type="company"
                :picture="company.picture"
                :name="company.company"
                :text="$options.filters.firstLetters(company.company)"
                size="36"
              />
              <div class="data">
                <we-text-tooltip
                  class="name"
                  :text="company.company"
                  lines="1"
                />
                <we-text-tooltip
                  class="email"
                  :text="company.email"
                  lines="1"
                />
              </div>
            </div>
            <div class="column only-desktop">
              <we-text-tooltip
                class="email"
                :text="company.phone1 || '-'"
                lines="1"
              />
            </div>
            <div class="column only-desktop">
              <we-text-tooltip
                class="name"
                :text="company.document || '-'"
                lines="1"
              />
            </div>
            <div class="column only-desktop">
              <we-text-tooltip
                class="name"
                :text="$options.filters.dateTime(company.created_at)"
                lines="1"
              />
            </div>
            <div class="column only-desktop">
              <we-text-tooltip
                class="name"
                :text="$options.filters.dateTime(company.updated_at)"
                lines="1"
              />
            </div>
            <div class="column only-mobile">
              <we-button
                class="primary inherit mobile-icon"
                text="Email"
                icon="envelope"
                @click="emailCompany(company)"
              />
            </div>
            <div class="column only-mobile">
              <we-button
                class="green inherit mobile-icon"
                text="Whats"
                :icon="['fab', 'whatsapp']"
                @click="messageCompany(company)"
              />
            </div>
          </div>
        </div>
      </template>
    </board-list>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      massUpdateOpened: false,
      confirmDeleteOpened: false,
      createValid: false,
      search: "",
      loadingBtn: false,
      loadingCompanies: true,
      initialPage: 1,
      sortElements: [
        {
          name: this.$t("column-1"),
          orderBy: "company",
          direction: "desc"
        },
        {
          name: this.$t("column-2"),
          orderBy: "phone1",
          direction: "desc"
        },
        {
          name: this.$t("column-3"),
          orderBy: "document",
          direction: "desc"
        },
        {
          name: this.$t("column-4"),
          orderBy: "created_at",
          direction: "desc"
        },
        {
          name: this.$t("column-5"),
          orderBy: "updated_at",
          direction: "desc"
        }
      ]
    };
  },
  computed: {
    companies() {
      return this.$store.getters.getCompanies;
    },
    companiesPages() {
      return this.$store.getters.getCompaniesPages;
    },
    companiesSort() {
      return this.$store.getters.getCompaniesSort;
    },
    filterActive() {
      return this.$store.getters.getFilterActive;
    },
    exportParams() {
      return this.$store.getters.getExportParams;
    }
  },
  methods: {
    ...mapActions([
      "companiesRequest",
      "createCompanyRequest",
      "searchCompaniesRequest",
      "filterCompaniesRequest",
      "exportRequest",
      "exportFilteredAsCsvRequest",
      "deleteFilteredRecords"
    ]),
    async deleteFilteredData() {
      this.loadingCompanies = true;
      await this.deleteFilteredRecords();
      await this.doRequests();
    },
    emailCompany(company) {
      if (company.email.length > 0) {
        window.open(`mailto:${company.email}`);
      } else {
        const snackBar = {
          title: this.$t("email-send-error-title"),
          description: this.$t("email-send-error-description"),
          status: "error"
        };

        this.$store.commit("setSnackBar", snackBar);
        this.$store.commit("addSnackBar");
      }
    },
    messageCompany(company) {
      if (company.phone1.length > 0) {
        let formatedPhone = this.$options.filters.clearPhoneNumber(
          company.phone1
        );
        window.open(`https://wa.me/55${formatedPhone}`, "_blank");
      } else {
        const snackBar = {
          title: this.$t("message-send-error-title"),
          description: this.$t("message-send-error-description"),
          status: "error"
        };

        this.$store.commit("setSnackBar", snackBar);
        this.$store.commit("addSnackBar");
      }
    },
    exportFile() {
      const payload = {
        entity: "companies"
      };

      this.exportRequest(payload);
    },
    exportFilteredFile(ext) {
      if (ext === "csv") {
        this.exportFilteredAsCsvRequest("companies-filtered");
      }
    },
    openImport() {
      this.$router.push({ name: "importCompanies" });
    },
    searchCompanies(val) {
      this.loadingCompanies = true;
      this.$store.commit("setFilterActive", false);
      let orderBy = {};
      if (this.companiesSort.orderBy) {
        orderBy = {
          orderBy: this.companiesSort.orderBy,
          direction: this.companiesSort.direction
        };
      }
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (val?.length > 0) {
          await this.searchCompaniesRequest({
            value: this.search,
            page: 1,
            ...orderBy
          });
        } else {
          await this.doRequests();
        }
        this.loadingCompanies = false;
      }, 600);
    },
    openCompany(id) {
      this.$router.push({ name: "company", params: { companyId: id } });
    },
    async orderBy(elem) {
      this.loadingCompanies = true;

      if (elem.direction === "desc") {
        elem.direction = "asc";
      } else {
        elem.direction = "desc";
      }

      this.$store.commit("setCompaniesSort", elem);

      let orderBy = {};
      if (elem.orderBy) {
        orderBy = {
          orderBy: elem.orderBy,
          direction: elem.direction
        };
      }

      if (this.filterActive) {
        await this.filterCompaniesRequest({
          filters: this.exportParams?.payload?.filters,
          page: this.companiesPages.current_page,
          ...orderBy
        });
      } else if (this.search.length > 0) {
        await this.searchCompaniesRequest({
          value: this.search,
          page: this.companiesPages.current_page,
          paginate: true,
          ...orderBy
        });
      } else {
        await this.companiesRequest({
          page: this.companiesPages.current_page,
          updateLocal: false,
          ...orderBy
        });
      }
      this.loadingCompanies = false;
    },
    async doRequests(reset) {
      this.loadingCompanies = true;
      if (reset) {
        this.companiesPages.current_page = this.initialPage;
        this.$store.commit("setFilterActive", false);
        this.$store.commit("setCompaniesSort", {
          orderBy: "",
          direction: "",
          name: ""
        });
      }

      if (this.filterActive) {
        await this.filterCompaniesRequest({
          filters: this.exportParams?.payload?.filters,
          page: this.companiesPages.current_page
        });
      } else if (this.search.length > 0) {
        await this.searchCompaniesRequest({
          value: this.search,
          page: this.companiesPages.current_page,
          paginate: true
        });
      } else {
        await this.companiesRequest({ page: this.companiesPages.current_page });
      }

      this.loadingCompanies = false;
    },
    async selectPage(val) {
      this.loadingCompanies = true;
      let orderBy = {};
      if (this.companiesSort.orderBy) {
        orderBy = {
          orderBy: this.companiesSort.orderBy,
          direction: this.companiesSort.direction
        };
      }

      if (this.filterActive) {
        await this.filterCompaniesRequest({
          filters: this.exportParams?.payload?.filters,
          page: val,
          ...orderBy
        });
      } else if (this.search.length > 0) {
        await this.searchCompaniesRequest({
          value: this.search,
          page: val,
          ...orderBy
        });
      } else {
        await this.companiesRequest({
          page: val,
          ...orderBy,
          updateLocal: false
        });
      }
      this.loadingCompanies = false;
    }
  },
  async mounted() {
    await this.doRequests(true);
    this.$store.commit("resetFilters");
  },
  beforeRouteUpdate(to, from, next) {
    if (to.hash.length > 0) {
      return;
    }
    if (from.name === "filterCompany") {
      this.search = "";
    }
    if (from.name === "importCompanies") {
      this.doRequests();
    }
    if (to.query.reload) {
      this.doRequests();
    }
    next();
  }
};
</script>

<style lang="scss">
#companies {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 69px calc(100vh - 69px);
  position: relative;
}
</style>
