<i18n>
{
  "en": {
    "title": "New Company",
    "input-1": "Corporate name",
    "input-2": "Website",
    "input-3": "Document",
    "create": "Create company",
    "custom-fields": "Custom fields"
  },
  "pt-BR": {
    "title": "Nova Empresa",
    "input-1": "Razão social",
    "input-2": "Website",
    "input-3": "CNPJ",
    "create": "Criar empresa",
    "custom-fields": "Campos customizados"
  }
}
</i18n>

<template>
  <modal-action @close="closeCreateCompany" :columns="!hasCustomFields ? 1 : 2">
    <template #view>
      <div class="modal">
        <div class="modal-header">
          <div class="name">
            <div class="icon">
              <font-awesome-icon icon="building" />
            </div>
            {{ $t("title") }}
          </div>
          <div class="options">
            <we-icon-button icon="times" @click="closeCreateCompany" />
          </div>
        </div>
        <div class="modal-body">
          <v-form ref="form" v-model="createValid">
            <div class="fields">
              <we-input
                v-model="payload.company"
                cy="create-company-name"
                isRequired
                type="text"
                :label="$t('input-1')"
                :placeholder="$t('input-1')"
              />
              <we-input
                class="fill-input"
                v-model="payload.website"
                type="text"
                :label="$t('input-2')"
                :placeholder="$t('input-2')"
              />
              <we-input
                v-if="$i18n.locale === 'pt-BR'"
                v-model="payload.document"
                type="text"
                :label="$t('input-3')"
                :placeholder="$t('input-3')"
                :mask="'##.###.###/####-##'"
              />
              <we-input
                v-if="hasCustomFields"
                class="double-column"
                type="div"
                :placeholder="$t('custom-fields')"
              />
              <we-input
                v-for="(field, i) in customFieldsRequired"
                :class="field.layout"
                :key="i"
                :type="field.type"
                :label="field.label"
                :items="field.values"
                :isRequired="field.is_required"
                :readonly="field.is_read_only"
                :placeholder="field.placeholder"
                v-model="companyCustomFields[field.name]"
                :mask="field.mask"
              />
            </div>
          </v-form>
        </div>
        <div class="bottom">
          <we-button
            class="green"
            cy="create-company"
            :text="$t('create')"
            icon="plus"
            :loading="loadingBtn"
            @click="createCompany"
            :disabled="!createValid"
          />
        </div>
        <we-loading-overflow :loading="loading" />
      </div>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      createValid: false,
      loadingBtn: false,
      loading: true,
      payload: {
        company: "",
        website: "",
        document: ""
      }
    };
  },
  computed: {
    customFieldsInputs() {
      return this.$store.getters.getCompanyCustomFieldsInputs;
    },
    customFieldsRequired() {
      return this.customFieldsInputs.filter(e => e.is_required);
    },
    companyCustomFields() {
      return this.$store.getters.getCompanyCustomFields;
    },
    hasCustomFields() {
      return this.customFieldsRequired.length > 0;
    }
  },
  methods: {
    ...mapActions(["createCompanyRequest", "customFieldsRequest"]),
    closeCreateCompany() {
      if (this.$route.name.includes("createDealCompany")) {
        this.$router.push({
          name: "pipelineDeal",
          params: { dealId: this.$route.params.dealId }
        });

        return;
      }

      this.$router.push({ name: "companies" });
    },
    async createCompany() {
      this.loadingBtn = true;
      const payload = {
        ...this.payload,
        custom_fields: [this.companyCustomFields]
      };
      const response = await this.createCompanyRequest(payload);

      this.loadingBtn = false;

      if (this.$route.name.includes("createDealCompany")) {
        this.$router.push({
          name: "pipelineDeal",
          params: { dealId: this.$route.params.dealId, company: response.data }
        });

        return;
      }

      if (response?.data?.id) {
        this.$router.push({
          name: "company",
          params: { companyId: response.data.id },
          query: { reload: true }
        });
      }
    }
  },
  async mounted() {
    this.$store.commit("clearCompanyCustomFieldsValues");
    await this.customFieldsRequest();
    this.loading = false;
  }
};
</script>
